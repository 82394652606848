<template>
  <nav>
    <div class="nav-links">
      <router-link class="nav-link" to="/">home</router-link>
      <router-link class="nav-link" to="/about">merch</router-link>
      <router-link class="nav-link" to="/about">events</router-link>
      <router-link class="nav-link" to="/about">about</router-link>
    </div>
    <div class="nav-logo">
      <img src="./images/Logo.png" alt="Kahve Verde Logo" />
    </div>
    <div class="nav-socials">
      <a href="" class="nav-social"><i class="fa fa-instagram"></i></a>
      <a href="" class="nav-social"><i class="fa fa-facebook-square"></i></a>
      <a href="" class="nav-social"><i class="fa fa-tumblr-square"></i></a>
      <a href="" class="nav-social"><i class="fa fa-twitter-square"></i></a>
    </div>
  </nav>
  <h1><font-awesome-icon icon="”hat-wizard”" /></h1>
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=STIX+Two+Text&family=Tenor+Sans&display=swap");
/* media size screens */
:root {
  --green-color: #1f3933;
  --black-color: #000;
  --white-color: #dadada;
  --cream-color: #d5cab4;
}

router-view {
  height: 100%;
  width: 100vw;
  color: white;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  position: absolute;
  top: 0;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /*background: #2c3e50;*/
}
.nav-links,
.nav-logo,
.nav-socials {
  width: 20%;
  display: flex;
  justify-content: space-evenly;
}
.nav-links {
  /*background-color: aliceblue;*/
  color: white;
}

.nav-link {
  text-decoration: none;
  color: black;
  font-size: 20px;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.nav-logo {
  /*background-color: blue;*/
  width: 20%;
}
.nav-logo > img {
  width: 100%;
}

.nav-socials {
  /*background-color: green;*/
}

.nav-social {
  color: black;
  font-size: 20px;
}
</style>
