<template>
  <div class="home">
    <WelcomeSection />
    <BlackBar />
    <SloganSection />
    <MenuSection />
    <AboutUsSection />
    <AppSection />
    <FoodMenuSection />
    <InstagramSection />
  </div>
</template>

<script>
// @ is an alias to /src
import WelcomeSection from "@/components/WelcomeSection.vue";
import BlackBar from "@/components/BlackBar.vue";
import SloganSection from "@/components/SloganSection.vue";
import MenuSection from "@/components/MenuSection.vue";
import AboutUsSection from "@/components/AboutUsSection.vue";
import AppSection from "@/components/AppSection.vue";
import FoodMenuSection from "@/components/FoodMenuSection.vue";
import InstagramSection from "@/components/InstagramSection.vue";

export default {
  name: "HomeView",
  components: {
    BlackBar,
    WelcomeSection,
    SloganSection,
    MenuSection,
    AboutUsSection,
    AppSection,
    FoodMenuSection,
    InstagramSection,
  },
};
</script>
