<template>
  <div class="welcome-section">
    <div class="welcome-page-center">
      <div class="welcome-page-image-container">
        <img src="@/images/matcha.png" alt="Matcha Drink" class="welcome-img" />
      </div>
      <div class="welcome-page-words">
        <h1>KAHVE VERDE</h1>
        <h2>Good Morning! Come see us we're open</h2>
        <p>123 Some St. Columbus, Ohio</p>
        <button class="welcome-page-btn">location</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.welcome-section {
  background-color: var(--green-color);
  height: 95vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-page-center {
  display: flex;
  align-items: center;
  /*background-color: antiquewhite;*/
  width: 80rem;
  position: relative;
}

.welcome-img {
  max-height: 30rem;
}
.welcome-page-words {
  /*background-color: aqua;*/
  text-align: left;
  color: white;
  position: absolute;
  left: 22rem;
  display: flex;
  flex-direction: column;
}

/*.welcome-page-words > h1,*/
/*.welcome-page-words > .welcome-page-details {*/
/*  position: relative;*/
/*  top: 0px;*/
/*}*/

.welcome-page-words > h1 {
  font-size: 7.5rem;
  font-weight: 300;
  font-family: "STIX Two Text", serif;
  letter-spacing: -1.1rem;
  word-spacing: 2rem;
  /*background-color: blue;*/
  /*display: inline;*/
  /*position: relative;*/
  /*bottom: 1.5rem;*/
  margin-top: 1rem;
}

.welcome-page-words > h2 {
  font-size: 2.8rem;
  font-family: "Tenor Sans", sans-serif;
  font-weight: 400;
  /*background-color: red;*/
  display: inline;
  position: relative;
  bottom: 1.5rem;
}

.welcome-page-words > p {
  font-family: "Tenor Sans", sans-serif;
  /*background-color: green;*/
  font-size: 1.7rem;
  font-weight: 400;
  display: inline;
  position: relative;
  bottom: 1.5rem;
}
.welcome-page-btn {
  color: white;
  background-color: black;
  border: none;
  width: 7.5rem;
  height: 3.25rem;
  border-radius: 10px;
  position: relative;
  bottom: 1rem;
  margin-right: 0.1rem;
}
</style>
