<template>
  <div class="menu-section">
    <div class="menu-container-left">
      <div class="menu-items-section">
        <h2>Drink Menu</h2>
        <div class="menu-items-container">
          <div>
            <img
              class="menu-icons MokaPot"
              src="@/images/MokaPot.png"
              alt="Moke Pot"
            />
            <p>drip coffee</p>
            <p>iced coffee</p>
          </div>
          <div>
            <img
              class="menu-icons"
              src="@/images/TeaPot-Icon.png"
              alt="Moke Pot"
            />
            <p>matcha</p>
            <p>chai</p>
          </div>
        </div>
      </div>
      <div class="menu-image-container">
        <!--          <img src="" alt="">-->
      </div>
    </div>
    <div class="menu-container-right">
      <div class="menu-items-section">
        <h2>Food Menu</h2>
        <div class="menu-items-container">
          <div>
            <img
              class="menu-icons"
              src="@/images/Croissant-Icon.png"
              alt="Moke Pot"
            />
            <p>croissant</p>
          </div>
          <div>
            <img
              class="menu-icons"
              src="@/images/Cookies-Icon.png"
              alt="Moke Pot"
            />
            <p>
              vegan matcha <br />
              cookies
            </p>
          </div>
        </div>
      </div>
      <div class="menu-image-container">
        <!--          <img src="" alt="">-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuSection",
};
</script>

<style>
.menu-section {
  background-color: var(--white-color);
  height: 55rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.menu-container-left,
.menu-container-right {
  /*background-color: #42b983;*/
  width: 35rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menu-items-section,
.menu-image-container {
  margin: 1rem 0;
}
.menu-icons {
  height: 35px;
  width: 35px;
  /*background-color: #1f3933;*/
}
.menu-container-left h2,
.menu-container-right h2 {
  font-size: 3rem;
  color: var(--black-color);
  font-weight: 500;
}
.menu-items-section {
  width: 100%;
}
.menu-items-container {
  /*background-color: #0fb48f;*/
  color: black;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0.5rem 0 1rem;
}
.menu-items-container p {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  font-weight: 400;
}
.menu-image-container {
  background-color: #484848;
  height: 20rem;
  width: 15rem;
}
.menu-items-container > div {
  /*background-color: #d5cab4;*/
}

/*RIGHT SIDE*/
.menu-container-right {
  flex-direction: column-reverse;
}
</style>
