<template>
  <div class="aboutUs-section">
    <div class="aboutUs-container">
      <h2>
        can't blend in <br />
        when you stand out
      </h2>
      <div class="aboutUs-bottom-container">
        <div class="aboutUs-image-container">
          <!--        <img src="" alt="">-->
        </div>
        <div class="aboutUs-text-container">
          <h3>Kahve Verde</h3>
          <p>
            is built to serve top-notch street coffee to the city of Columbus,
            while also bringing diversity and uniting minorities at our doors.
          </p>
          <button>about us</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsSection",
};
</script>

<style>
.aboutUs-section {
  height: 40rem;
  background-color: var(--green-color);
  color: var(--white-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutUs-container {
  /*background-color: #42b983;*/
  width: 45rem;
}

.aboutUs-container h2 {
  font-size: 4rem;
  font-weight: 400;
  /*background-color: #d5cab4;*/
}
.aboutUs-bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 2rem 0;
}
.aboutUs-image-container {
  width: 16rem;
  height: 18rem;
  background-color: #d9d9d9;
}

.aboutUs-text-container {
  width: 20rem;
  /*background-color: #2c3e50;*/
}

.aboutUs-text-container h3 {
  color: black;
  font-size: 1.8rem;
}

.aboutUs-text-container p {
  font-size: 1.3rem;
}

.aboutUs-text-container button {
  background-color: var(--black-color);
  color: var(--white-color);
  border: none;
  width: 7rem;
  height: 3.25rem;
  border-radius: 10px;
  margin: 1rem 0;
}
</style>
