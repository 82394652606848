<template>
  <div class="instagram-section">
    <div class="instagram-container">
      <h2>follow us on instagram @<span>KahveVerde</span></h2>
      <div class="instagram-grid-container">
        <div class="instagram-image-container"></div>
        <div class="instagram-image-container"></div>
        <div class="instagram-image-container"></div>
        <div class="instagram-image-container"></div>
        <div class="instagram-image-container"></div>
        <div class="instagram-image-container"></div>
        <div class="instagram-image-container"></div>
        <div class="instagram-image-container"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstagramSection",
};
</script>

<style>
.instagram-section {
  height: 49rem;
  background-color: var(--green-color);
  color: white;
  padding: 4rem 0;
}
.instagram-container {
  /*background-color: #8a8a8a;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.instagram-container h2 {
  /*background-color: #42b983;*/
  font-size: 2rem;
  margin: 1rem 0;
}
.instagram-container h2 span {
  /*background-color: #4b6759;*/
  font-size: 2rem;
  color: var(--cream-color);
}
.instagram-grid-container {
  /*background-color: #2c3e50;*/
  height: 35rem;
  width: 80rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.instagram-image-container {
  height: 15rem;
  width: 15rem;
  margin: 0 1rem;
  background-color: #171717;
}
</style>
