<template>
  <div class="m-scroll">
    <div class="m-scroll__title">
      <div>
        <p>
          | Hello theres going to be alot of information displayed here, such
          displaying date and time for other location and weather for today and
          tomorrow Hello theres going to be alot of information displayed here,
          such as: displaying date and time for other location and weather for
          today and tomorrow
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlackBar",
};
</script>

<style>
.m-scroll {
  display: flex;
  position: relative;
  width: 100%;
  height: 2.5rem;
  margin: auto;
  background-color: var(--black-color);
  overflow: hidden;
  z-index: 1;
}
.m-scroll__title {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transform: scale(2);
  transition: all 10s ease;
}
.m-scroll__title > div {
  display: flex;
  animation: scrollText 33s infinite linear;
}
.m-scroll__title > div > p {
  font-size: 0.5rem;
  margin: 0;
  color: white;
  transition: all 2s ease;
}

/*div:hover {
  animation-play-state: paused;
}*/
@keyframes scrollText {
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(-50%);
  }
}
</style>
