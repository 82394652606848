<template>
  <div class="slogan-section">
    <div class="slogan-container">
      <p class="slogan-p-left">
        A mobile coffee shop built to serve top-notch street coffee to the city
        of Columbus
      </p>
    </div>
    <div class="slogan-container slogan-container2">
      <p class="slogan-p-right">
        A mobile coffee shop built to serve top-notch street coffee to the city
        of Columbus
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SloganSection",
};
</script>

<style>
.slogan-section {
  height: 15rem;
  background-color: var(--black-color);
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.slogan-container {
  width: 26rem;
  /*background-color: #2c3e50;*/
}

.slogan-p-left {
  font-size: 2rem;
  text-align: left;
}
.slogan-p-right {
  font-size: 2rem;
  text-align: right;
}
</style>
