<template>
  <div class="foodMenu-Section">
    <div class="foodMenu-container">
      <div class="foodMenu-box">
        <div class="foodMenu-image-container">
          <!--          <img src="" alt="">-->
        </div>
        <div class="foodMenu-text-container">
          <h3>croissant</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna
          </p>
        </div>
      </div>
      <div class="foodMenu-box">
        <div class="foodMenu-image-container">
          <!--          <img src="" alt="">-->
        </div>
        <div class="foodMenu-text-container">
          <h3>vegan matcha cookie</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FoodMenuSection",
};
</script>

<style>
.foodMenu-Section {
  height: 63rem;
  background-color: var(--white-color);
  padding: 5rem 0;
}
.foodMenu-container {
  height: 53rem;
  /*background-color: #b5d0ec;*/
  color: black;
  display: flex;
  justify-content: center;
}
.foodMenu-box {
  margin: 0 1rem;
  width: 34rem;
  /*background-color: #d5cab4;*/
}
.foodMenu-image-container {
  margin: 0 auto;
  height: 40rem;
  width: 95%;
  background-color: #818181;
}
.foodMenu-text-container h3 {
  font-size: 2.25rem;
  font-weight: 500;
  margin: 2.5rem 0 1rem;
}
.foodMenu-text-container p {
  font-size: 1.4rem;
  width: 29rem;
  margin: 0 auto;
}
</style>
