<template>
  <div class="app-section">
    <div class="app-container">
      <div class="app-image-container">
        <img src="@/images/PhoneMock.png" alt="phone mock up" />
      </div>
      <div class="app-text-container">
        <h3>Earn rewards while skipping the line</h3>
        <p>Place your order ahead of time and earn rewards with each cup</p>
        <button>download app</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSection",
};
</script>

<style>
.app-section {
  height: 45rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-container {
  /*background-color: #2c3e50;*/
  color: white;
  width: 95rem;
  display: flex;
  align-items: center;
}
.app-image-container {
  width: 35rem;
  height: 45rem;
  overflow: hidden;
  /*background-color: #d5cab4;*/
}
.app-image-container img {
  height: 48rem;
  margin: 0 auto;
}
.app-text-container {
  text-align: left;
  margin-left: 2rem;
}
.app-text-container h3 {
  font-size: 2.5rem;
}
.app-text-container p {
  font-size: 2rem;
  width: 33rem;
  color: var(--cream-color);
  margin: 1.5rem 0;
}

.app-text-container button {
  width: 10rem;
  height: 2.5rem;
  border: none;
  border-radius: 10px;
  background-color: var(--white-color);
}
</style>
